<template>
  <div>
    <v-row dense class="mx-1">
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por comprador, fornecedor, código ou oc" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="onChangeSearchText"/>
    </v-row>
    <px-line-filter :options="statusOptions" v-model="selectedStatus"
                    :on-select-item="onSelectItem">
    </px-line-filter>
  </div>
</template>

<script>
import { onlyNumbers } from '@/utils/regex-utils';

export default {
  props: {
    onClickRefresh: {
      type: Function,
      required: true,
    },
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onChangeSearchText() {
      this.onChangeFilter(this.buildFilter());
    },
    onSelectItem() {
      this.onChangeFilter(this.buildFilter());
    },
    buildFilter() {
      let filter = 'situacao not in ("RASCUNHO")';
      if (this.selectedStatus && this.selectedStatus.value) {
        filter = `situacao in ('${this.selectedStatus.value.join('\',\'')}')`;
      }
      if (this.searchText) {
        if (filter) {
          filter += ' and ';
        }
        const idFilter = onlyNumbers(this.searchText) ? ` or id = ${onlyNumbers(this.searchText)}` : '';
        filter += `codigoInterno like '%${this.searchText}%' or comprador.nome like '%${this.searchText.toLowerCase()}%' or fornecedor.nome like '%${this.searchText.toLowerCase()}%'${idFilter}`;
      }
      return filter;
    },
  },
  data() {
    return {
      statusOptions: [
        {
          id: 'TODAS',
          description: 'Todas',
          value: null,
        },
        {
          id: 'ABERTAS',
          description: 'Abertas',
          value: ['PENDENTE_COMPRADOR', 'PENDENTE_FORNECEDOR'],
        },
        {
          id: 'ELABORACAO',
          description: 'Em elaboração',
          value: ['ELABORACAO'],
        },
        {
          id: 'CANCELADAS',
          description: 'Canceladas',
          value: ['CANCELADA', 'RECUSADA'],
        },
        {
          id: 'FINALIZADAS',
          description: 'Finalizadas',
          value: ['APROVADA'],
        },
      ],
      selectedStatus: null,
      searchText: null,
    };
  },
  created() {
    [this.selectedStatus] = this.statusOptions;
    this.onChangeFilter(this.buildFilter());
  },
};
</script>
